import axios from 'axios';
import React, { useState, useEffect } from "react";
import Layout from '../Layout';
import useSWR from 'swr';
import { Row, Col } from 'react-bootstrap';
import { useAppData } from '../contexts/AppDataProvider';
import styled from '@emotion/styled';
import ReactPaginate from 'react-paginate';
import User from './User'
import qs from 'query-string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltUp, faLongArrowAltDown } from '@fortawesome/free-solid-svg-icons';
import UISelect from '../shared/ui/Select';
import DragAndDrop from './DragAndDrop';
import useDebounce from '../shared/hooks/useDebounce';

const StyledHeading = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  color: #1db4b3;
  margin-bottom: 2rem;
`;

const SortButton = styled.button`
  background: transparent;
  border: transparent;
`;

const SelectContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
  justify-content: flex-end;
  display: flex;
`;

const CompanyUsers = () => {
  const defaultSiteId = qs.parse(location.search)["site_id"]
  const defaultCompanyId = qs.parse(location.search)["company_id"]
  const { companyID, companyName, companyPrefix } = useAppData();
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [query, setQuery] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [currentUISelectAction, setCurrentUISelectAction] = useState(null);
  const [filteredCompanyId, setFilteredCompanyId] = useState(defaultCompanyId || companyID);
  const [siteId, setSiteId] = useState(null);
  const [keyWord, setKeyWord] = useState();
  const debouncedSearchTerm = useDebounce(keyWord, 500);

  const { data: site_data } = useSWR(
    `/api/v2/company_admin/cs_managers/sites?company_id=${filteredCompanyId}`,
    { revalidateOnFocus: false },
  );

  const { data: handledCompanies } = useSWR(
    "/api/v2/company_admin/cs_managers/companies",
    { revalidateOnFocus: false },
  );

  useEffect(() => {
    if (handledCompanies?.length > 0) {
      setFilteredCompanyId(defaultCompanyId || handledCompanies[0].id)
    }
  }, [handledCompanies])

  useEffect(() => {
    if (site_data?.length > 0) {
      setSiteId(defaultSiteId || site_data[0].id)
    }
  }, [site_data])

  useEffect(() => {
   getData()
  }, [currentPage])

  useEffect(() => {
    if(currentPage == 1){
      getData()
    }else{
      setCurrentPage(1)
    }
  }, [query, filteredCompanyId, siteId])

  useEffect(() => {
    if(!showModal){
      setData([])
      getData()
    }
  }, [showModal])

  useEffect(() => {
    setQuery({...query, query: keyWord})
  }, [debouncedSearchTerm]);

  const getData = async() => {
    if (filteredCompanyId) {
      const searchString = qs.stringify({...query, page: currentPage, site_id: siteId, company_id: filteredCompanyId});
      const res = await axios.get(`/api/v2/company_admin/company_users?${searchString}`)
      setData(res.data.data)
      setPageCount(res.data.pagy.last);
    }
    
  }

  const sort = (field) => {
    if(field === query.sortedField){
      const order = query.sortOrder === 'desc' ? 'asc' : 'desc'
      setQuery({...query, sortOrder: order})
    }else{
      setQuery({...query, sortOrder: 'asc', sortedField: field})
    }
  }

  const handleDownloadCSV = async () => {
    try {
      const response = await axios.get(
        `/api/v2/company_admin/company_users/download_template?template_type=${currentUISelectAction}`,
        {
          responseType: 'blob',
        },
      );
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      let filename =
        currentUISelectAction == 'batch_resigned_download_template'
          ? 'batch_resignation_template.csv'
          : 'batch_upload_template.csv';
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error exporting CSV:', error);
    }
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setCurrentPage(selectedPage + 1)
  };

  const handleOnSelect = async (eventKey) => {
    if (eventKey == 'batch_upload' || eventKey == 'batch_resigned') {
      setShowModal(true);
    }
    setCurrentUISelectAction(eventKey);
  };

  const onCompanyChange = (value) => {
    setFilteredCompanyId(value);
  }

  useEffect(() => {
    if (
      currentUISelectAction == 'batch_resigned_download_template' ||
      currentUISelectAction == 'batch_upload_download_template'
    ) {
      handleDownloadCSV();
    }
  }, [currentUISelectAction]);

  return(
    <Layout>
      <Row className="d-flex justify-content-between">
        {handledCompanies?.length > 0 && (
          <Col md={4} sm={12}>
            <StyledHeading>Company</StyledHeading>
            <select
              name="selectedCompany"
              defaultValue={filteredCompanyId}
              className="form-control mb-5"
              onChange={(event) => onCompanyChange(event.target.value)}>
              {handledCompanies.map((company, index) => (
                <option
                  key={`companyname-${index}-${company.id}`}
                  value={company.id}>
                  {company.name}
                </option>
              ))}
            </select>
            {site_data?.length > 0 && (
              <select
                  name="siteId"
                  defaultValue={siteId}
                  className="form-control mb-4 d-block"
                  onChange={(event) => setSiteId(event.target.value)}>
                  <option disabled> -- Select a Company Site --</option>
                  {site_data?.map((siteObject) => (
                    <option key={`site-${siteObject.id}`} value={siteObject.id}>
                      {siteObject.name}
                    </option>
                  ))}
              </select>
            )}
          </Col>
        )}
        { handledCompanies?.length == 0 && <StyledHeading>{companyName} Users</StyledHeading>}
          <Col md={3}>
            <input
            className="form-control"
            type="search"
            placeholder = "search"
            onChange={event => {setKeyWord(event.target.value)}}
            />
          </Col>
          <DragAndDrop
            setShowModal={setShowModal}
            refetchData={getData}
            show={showModal}
            companyId={filteredCompanyId}
            siteId={siteId}
            title={
              currentUISelectAction == 'batch_upload'
                ? 'Batch Upload'
                : 'Batch Resigned'
            }
          />
      </Row>
      <Row>
        <SelectContainer>
          <UISelect
            className="d-inline-block"
            selectedDropDown={currentUISelectAction}
            handleOnSelect={handleOnSelect}
            text={'Batch resignation'}
            key={'batch_resignation'}
            options={[
              { text: 'Tag as resigned', key: 'batch_resigned' },
              {
                text: 'Download template',
                key: 'batch_resigned_download_template',
              },
            ]}
            white={true}
          />
          <UISelect
            className="d-inline-block ml-2"
            selectedDropDown={currentUISelectAction}
            handleOnSelect={handleOnSelect}
            text={'Batch Uploads'}
            key={'batch_update'}
            options={[
              { text: 'Add new employees', key: 'batch_upload' },
              {
                text: 'Download template',
                key: 'batch_upload_download_template',
              },
            ]}
          />
        </SelectContainer>
      </Row>
      <Row>
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                {companyPrefix ? <th scope="col">User ID</th> : null}
                <th scope="col">Email</th>
                <th scope="col">
                  <SortButton type="button" onClick={() => sort('users.first_name')}>
                    {
                      query.sortedField && query.sortedField === 'users.first_name' ?
                        query.sortOrder === 'asc' ? <FontAwesomeIcon icon={faLongArrowAltUp} /> :  <FontAwesomeIcon icon={faLongArrowAltDown} />
                      : null
                    }
                    &nbsp;First Name
                  </SortButton>
                </th>
                <th scope="col">
                  <SortButton type="button" onClick={() => sort('users.last_name')}>
                    {
                      query.sortedField && query.sortedField === 'users.last_name' ?
                        query.sortOrder === 'asc' ? <FontAwesomeIcon icon={faLongArrowAltUp} /> :  <FontAwesomeIcon icon={faLongArrowAltDown} />
                      : null
                    }
                    &nbsp;Last Name
                  </SortButton>
                </th>
                <th scope="col">
                  <SortButton type="button" onClick={() => sort('users.confirmed_at')}>
                    {
                      query.sortedField && query.sortedField === 'users.confirmed_at' ?
                        query.sortOrder === 'asc' ? <FontAwesomeIcon icon={faLongArrowAltUp} /> :  <FontAwesomeIcon icon={faLongArrowAltDown} />
                      : null
                    }
                    &nbsp;Date Confirmed
                  </SortButton>
                </th>
                <th scope="col">Employee Status</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((content) => (
                <User data={content} key={content.id} companyPrefix={companyPrefix} />
              ))}
            </tbody>
          </table>
        </div>
      </Row>
      <div className="custom-pagination">
       <ReactPaginate
        breakClassName={'page-item'}
        breakLinkClassName={'page-link'}
        containerClassName={'pagination'}
        pageClassName={'page-item'}
        pageLinkClassName={'page-link'}
        previousClassName={'page-item'}
        previousLinkClassName={'page-link'}
        nextClassName={'page-item'}
        nextLinkClassName={'page-link'}
        previousLabel={"prev"}
        nextLabel={"next"}
        breakLabel={"..."}
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        activeClassName={"active"}/>
      </div>
    </Layout>
  )
};

export default CompanyUsers;
